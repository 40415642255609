.App {
  text-align: center;
}

/* Hero Class Transitions */
.hero-class-enter,
.work-class-enter,
.contact-class-enter {
  opacity: 0;
  display: block;
}

.hero-class-enter-active,
.work-class-enter-active,
.contact-class-enter-active {
  opacity: 1;
  transition: all 1200ms;
  transition-timing-function: linear;
}

.hero-class-enter-done,
.hero-class-enter-done,
.hero-class-enter-done {
  opacity: 1;
  display: block !important;
  z-index: 10;
}

.hero-class-exit
.work-class-exit,
.contact-class-exit {
  opacity: 1;
}

.hero-class-exit-active,
.work-class-exit-active,
.contact-class-exit-active {
  opacity: 0;
  transition: all 1000ms;
  transition-timing-function: linear;
}

.hero-class-exit-done,
.work-class-exit-done,
.contact-class-exit-done {
  opacity: 0;
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-selector {
  background: linear-gradient(60deg, #ffffff, #dddddd, #ffffff, #ffffff, #ffffff);
  background-size: 1000% 1000%;

  -webkit-animation: AnimationName 7s ease infinite;
  -moz-animation: AnimationName 7s ease infinite;
  animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:91% 0%}
  50%{background-position:10% 100%}
  100%{background-position:91% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:91% 0%}
  50%{background-position:10% 100%}
  100%{background-position:91% 0%}
}
@keyframes AnimationName {
  0%{background-position:91% 0%}
  50%{background-position:10% 100%}
  100%{background-position:91% 0%}
}

.left-half::after {
  content: "";
  background: rgb(202, 0, 0);
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 100px;
  width: 2px;
}

.left-half::before {
  content: "";
  background: rgb(202, 0, 0);
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 1px;
  width: 100px;
}


@media only screen and (min-width: 770px) {

  .right-half::after {
    content: "";
    background: rgb(202, 0, 0);
    position: absolute;
    top: -10px;
    right: -10px;
    height: 100px;
    width: 1px;
  }
  
  .right-half::before {
    content: "";
    background: rgb(202, 0, 0);
    position: absolute;
    top: -10px;
    right: -10px;
    height: 2px;
    width: 100px;
  }
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}