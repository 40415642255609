@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.work-title {
  font-size: 38px;
  line-height: 1;
  height: min-content;
  margin-left: 16px;
}

.work-subtitle {
  font-size: 16px;
  line-height: 1;
  height:min-content;
  margin-top: auto;
  margin-bottom: 3px;
}

.div-shadow {
  box-shadow: 12px 12px 0px black;
}

.div-shadow-small {
  box-shadow: 6px 6px 0px black;
}

.button-shadow {
  box-shadow: 6px 6px 0px black;
}

.button-shadow:hover {
  box-shadow: none;
}

.contact-left {
  background-image: url(https://3sidedcube.com/app/themes/tsc-2018/img/svgs/top-blob.svg);
  position: absolute;
  background-size: cover;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  transform: scale(1.2);
}

.contact-right {
  background-image: url(https://3sidedcube.com/app/themes/tsc-2018/img/svgs/bottom-blob.svg);
  position: absolute;
  background-size: cover;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  transform: scale(1.2);
}

.work-image {
  height: 350px;
  width: 100%;
  background-image: url(./images/nama1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.work-image-2 {
  background-image: url(./images/pupexpress.png);
}

.work-image-3 {
  background-image: url(./images/netflix.png);
}

.work-image-4 {
  background-image: url(./images/alltrails.png);
}

.work-image-5 {
  background-image: url(./images/lashes.png);
}

.work-image-6 {
  background-image: url(./images/underground.png);
}

.background-shade {
  background-color: rgba(18, 18, 18, .95);
}

@media only screen and (max-width: 768px) {

  .hero-background {
    height: 40rem;
  }

  .work-image {
    height: 230px;
  }

  .work-title {
    font-size: 18px;
  }

  .work-subtitle {
    font-size: 12px ;
  }
  
}